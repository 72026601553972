<template>
  <div>
    <ConfirmModal ref="DeleteConfirmModal" @ok="OnConfirmed" content="This will be removed. Continue?" />
    <UserForm ref="UserForm" @payload="onPayload" />
    <Search @search="onSearch" v-if="!is_view">
      <BulkUploadResult ref="BulkResultModal" title="Result" :result="bulkUploadContext" />
      <ConfirmModal ref="ConfirmModal" :content="`${bulkUploadContext.items.length} items will be uploaded. Continue?`" @ok="bulkSubmit" />
      <ProgressModal ref="ProgressModal" :counter="UploadProgressCounter" @cancel="onStopBulkUpload" />
      <CRow>
        <CCol sm="12">
          <CTableWrapper
            :items="list"
            :fields="FIELDS"
            header-icon="cil-user"
            :add-btn="true"
            :count="count"
            caption="사용자"
            hover
            small
            fixed
            :viewBtn="false"
            :editBtn="true"
            @import="onImport"
            @import-example="onImportExample"
            @add="onAdd"
            @edit="onEdit"
            @delete="onDelete"
            @delete-checked-items="onDeleteCheckedItems"
          />
        </CCol>
      </CRow>
      <CRow class="justify-content-end" v-if="pageTotal > 1">
        <CPagination
          :activePage.sync="pageCurrent"
          :pages="pageTotal"
          @update:activePage="onUpdatePage"
        >
        </CPagination>
        <CInput type="number" v-model="pageDirectSet" @change="onChangePageDirectSet" class="px-3 w-auto" style="max-width: 120px;" />
      </CRow>
      <input type="file" id="_upload_file" hidden accept=".csv" @change="onFileChange"/>
    </Search>
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import util from '@/utility'
import Search from '@/containers/Search.vue'
import ConfirmModal from '@/components/modals/ConfirmModal.vue'
import ProgressModal from '@/components/modals/ProgressModal.vue'
import BulkUploadResult from '@/components/modals/BulkResultModal.vue'
import CTableWrapper from '@/components/EdxTable'
import UserForm from './UserForm.vue'
import utility from '@/utility.js'
import { mapState } from 'vuex'

export default {
  name: 'Users',
  components: {
    Search,
    CTableWrapper,
    BulkUploadResult,
    ConfirmModal,
    ProgressModal,
    UserForm,
  },
  data () {
    return {
      FIELDS: [
        // {key: 'checkbox', label: '', _style:'width:50px;'},
        {key: 'organization_name', label: 'Organization', _classes: 'align-middle'},
        {key: 'avatar', label: '', _style:'width: 80px;'},
        {key: 'name', label: 'Name', _classes: 'align-middle'},
        {key: 'email', label: 'E-Mail', _classes: 'align-middle'},
        {key: 'cellphone', label: 'Phone', _classes: 'align-middle'},
        {key: 'auth_name', label: 'Level', _classes: 'align-middle'},
        {key: 'ops', label: 'Operations', _style: 'width: 100px;'}
      ],
      pageTotal: 1,
      pageCurrent: 1,
      pageDirectSet: 1,
      count: 0,
      searchText: '',
      list: [],

      bulkUploadContext: {
        header: [],
        items: [],
        current: 0,
        maxPage: 0,
        okCnt: 0,
        errors: []
      },

      confirm_show: false,

      is_view:false,
      selected_user:null
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    UploadProgressCounter() {
      return parseInt((Math.min(this.bulkUploadContext.current, this.bulkUploadContext.maxPage) / Math.max(this.bulkUploadContext.maxPage, 1)) * 100);
    }
  },
  methods: {
    getList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        search: this.searchText,
        page: this.pageCurrent
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      axios.get(`/api/install/users/${query_string}`)
        .then(result => {
          this.list = result.data.list.map(el => {
            let status = 'danger';
            if (this.capability.user_profile.id === el.id) status = 'success';
            el.avatar = {
              url: el.image_signed_url,
              status: status
            };
            return el;
          });
          this.pageTotal = result.data.page.total;
          this.pageCurrent = result.data.page.current;
          this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        });
    },
    addBulk() {
      if (this.bulkUploadContext.current > this.bulkUploadContext.maxPage) {
        this.$refs.ProgressModal.close();
        this.$refs.BulkResultModal.open();
        this.getList();
        // console.log(this.bulkUploadContext.errors);
        return;
      }
      // 현재 페이지의 N개 ($resource.BULK_UPLOAD_CNT) 를 잘라 targetItems로 만든다.
      let targetItems = this.bulkUploadContext.items.slice(
        this.$resource.BULK_UPLOAD_CNT*this.bulkUploadContext.current,
        Math.min(this.$resource.BULK_UPLOAD_CNT*(this.bulkUploadContext.current+1), this.bulkUploadContext.items.length)
      );
      axios.post(`/api/install/upload/users/`, {
        page_total: this.bulkUploadContext.maxPage,
        page_current: this.bulkUploadContext.current,
        items: targetItems,
        headers: this.bulkUploadContext.header
      })
        .then(result => {
          // console.log(result.data);
          for (result of result.data.result) {
            if (!result.result) {
              this.bulkUploadContext.errors.push(result);
            } else {
              this.bulkUploadContext.okCnt++;
            }
          }
          this.bulkUploadContext.current++;
          this.addBulk();
        })
        .catch(error => {
          console.error(error);
        })
    },
    onSearch(text) {
      this.searchText = text;
      this.getList();
    },
    onImport() {
      document.getElementById('_upload_file').value=null;
      document.getElementById('_upload_file').click();
    },
    onImportExample(){
      const sample_format = [{
        "email":"sample_user@sample.com",
        "password":"12345678",
        "auth_level":"Operator", // user
        "name":"My Name",
        "cellphone":"010-1234-5678",
        "organization_name":"Super Smart Organization"
      }]
      let filename = "users_sample.csv"
      utility.DownloadCSV(sample_format,filename);
    },
    onAdd() {
      this.$refs.UserForm.open({
        title: 'New User',
        user: null
      });
    },
    onEdit(id, index, user) {
      this.$refs.UserForm.open({
        title: 'Edit User',
        user: user
      });
    },
    onPayload(payload, id) {
      if (!id) {
        axios.post(`/api/install/users/`, payload)
          .then(result => {
            this.getList();
            this.$notify.success({
              title: '완료',
              message: `항목이 추가되었습니다`,
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `항목을 추가할 수 없습니다: ${error.response.data.error}`,
              offset: 30
            });
          })
      } else {
        axios.put(`/api/install/users/${id}/`, payload)
          .then(result => {
            this.getList();
            this.$notify.success({
              title: '완료',
              message: `항목이 변경되었습니다`,
              offset: 30
            });
          })
          .catch(error => {
            console.error(error);
            this.$notify.error({
              title: '오류',
              message: `항목을 변경할 수 없습니다: ${error.response.data.error}`,
              offset: 30
            });
          })
      }
    },
    onFileChange(e) {
      const files = e.target.files;
      const reader = new FileReader;
      if (files.length !== 1) return;
      reader.onload = event => {
        let payload = event.target.result;
        payload = payload.replace(/[\r\n]+$/, '');
        payload = util.CSVToArray(payload);

        // Context init & cascaded function call
        this.bulkUploadContext.maxPage = parseInt((payload.length-1) / this.$resource.BULK_UPLOAD_CNT);
        this.bulkUploadContext.current = 0;
        this.bulkUploadContext.header = payload[0];
        this.bulkUploadContext.items = payload.slice(1);
        this.bulkUploadContext.okCnt = 0;
        this.bulkUploadContext.errors = [];
        this.$refs.ConfirmModal.open();
      }
      reader.readAsText(files[0]);
    },
    onUpdatePage(e) {
      this.pageDirectSet = ""+this.pageCurrent;
      this.getList();
    },
    onChangePageDirectSet() {
      if (!this.pageDirectSet || Number(this.pageDirectSet) <= 0) this.pageDirectSet = "1";
      else if (+this.pageDirectSet >= this.pageTotal) this.pageDirectSet = ""+this.pageTotal;
      this.pageCurrent = +this.pageDirectSet;
      this.getList();
    },
    bulkSubmit() {
      this.$refs.ProgressModal.open();
      this.addBulk();
    },
    onStopBulkUpload() {
      this.bulkUploadContext.current = this.bulkUploadContext.maxPage + 1;
      this.$refs.ProgressModal.close();
    },
    onDelete(id) {
      // console.log(id)
      // console.log(this.capability.user_profile.id)
      if(id===this.capability.user_profile.id){
        this.$notify.warning({
          title: '확인',
          message:`자기 자신의 계정은 삭제할 수 없습니다.`,
          offset: 30
        });
        return;
      }
      this.$refs.DeleteConfirmModal.open(id);
    },
    onDeleteCheckedItems(delete_items) {
      const fd = new FormData;
      let id_list = delete_items.map((item)=>{
        return item.id;
      })
      fd.append("id_list", JSON.stringify(id_list))
      axios.delete(`/api/install/users/`, {data:fd})
        .then(result => {
          // console.log(result);
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(error => {
          console.error(error);
          this.$notify.error({
            title: '오류',
            message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        })
    },
    OnConfirmed(payload) {
      axios.delete(`/api/install/users/${payload}/`)
        .then(result => {
          this.getList();
          this.$notify.success({
            title: '완료',
            message: `항목이 삭제되었습니다`,
            offset: 30
          });
        })
        .catch(e => {
          console.error(e);
          this.$notify.error({
            title: '오류',
            message: `항목을 삭제할 수 없습니다: ${error.response.data.error}`,
            offset: 30
          });
        });
    },
    
  }
}
</script>
